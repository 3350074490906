import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Video from '~components/VideoModal'
import { Images } from '~data'
 import Hero from "./style"
export default function HeroSection(){
return(
<Hero backgroundImage={Images.agency.heroImg3} backgroundOverlay={Images.agency.heroImgOverlay}>
  <Container>
    <Row className="align-items-center justify-content-center justify-content-md-start">
      {/* Welcome content Area */}
      <Col className="col-xxl-6 col-xl-7 col-lg-8 col-md-8 col-xs-11 order-2 order-lg-1">
        <Hero.Content mb="40px" mbMD="40px" mt="30px" mtLG="0">
        <Hero.Subtitle as="h3" fontColor="#fee856" mb="28px">Первые 2 урока бесплатные</Hero.Subtitle>
        <Hero.Title as="h1" fontColor="#fff">Python Foundations</Hero.Title>
          <Hero.Text>В этом курсе для начинающих, вы научитесь основам программирования,
            <br className="d-none d-sm-block" />
            напишите свою первую программу, ознакомьтесь с переменными, типами данных, функциями, списками, узнаете что такое цикл, классы, объекты и т.п.</Hero.Text>
          <Hero.Video mt="40px">
            <Video id="FyjBb8L3Ij0" className="video-block">
              <i className="fa fa-play" />
              <span className="btn-text">Посмотрите 1-минутное
                <br className="d-none d-xs-block" />видео об этом курсе</span>
            </Video>
          </Hero.Video>
        </Hero.Content>
      </Col>
      {/*/ .Welcome Content Area */}
    </Row>
  </Container>
</Hero>
)
}

const ServiceData = [

        {
          id:"as1",
          icon: "fas fa-file-code",
          iconColor: "#254BDD",
          title: "Работать с изображениями, аудио и видео файлами",
          text:
            "Работать с базами данных, манипулировать изображения, аудио и видео файлы",
        },
        {
          id:"as2",
          icon: "fab fa-js-square",
          iconColor:"#F7DF1E",
          title: "Создать портфолио",
          text:
            "Создавать простые и сложные модульные программы: от консольных скриптов до чат-ботов",
        },
        {
          id:"as3",
          icon: "fab fa-python",
          iconColor:"#346C99",
          title: "Программированию на Python",
          text:
            "Изучите основы языка и узнаете что такое модули, функциональное программирование и ООП.",
        },
        {
          id:"as4",
          icon: "fas fa-layer-group",
          iconColor:"#2BA977",
          title: "Работать с популярными фреймворками — Django, Flask",
          text:
            "Узнаете как разрабатывать сложные веб приложения. А также, узнаете что такое MVC моделирование.",
        },
        {
          id:"as5",
          icon: "fas fa-server",
          iconColor:"#6001d3",
          title: "Работать в команде",
          text:
            "Научитесь коммандной работе - эффективной и продуктивной рабочей деятельности. Научим вас действовать слаженно, продуманно и с опорой на общую цель.",
        },
  
      ]
  export default ServiceData;